import { useRef, useState } from "react";

import {
    MDBLightbox,
    MDBLightboxItem,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from "mdb-react-ui-kit";


  export default function ImageSelection({name, images, image}) {
    const [mainImage, setMainImage] = useState(image)
console.log(images)
console.log(image)
    const changeMainImage = (newImage) => {
        setMainImage(newImage);
      }
  
    return(
        <>
        <MDBLightbox className="d-flex justify-item-center w-auto m-auto">
    <MDBLightboxItem
      src={`${mainImage}`}
      alt={name}
      caption={name}
      style={{
        objectFit: "cover",
        height: "400px",
        width: "400px",
        maxWidth: "100%",
        maxHeight: "100%",
        aspectRatio: "1/1",
      }}
      fullscreenSrc={`${mainImage}?h=800`}
    />
    <p>click on image to enlarge</p>
    <div className="text-center">
    {images.map((image, index) => (
      <> 
      <MDBBtn size="lg" className="m-2 w-75" color="dark" key={index} onClick={() => changeMainImage(image.url)} style={{cursor: 'pointer'}} >{index % 2? "Back":"Front"}</MDBBtn>
      </>
       
        ))}
</div>
  </MDBLightbox>


        </>

    )
  }
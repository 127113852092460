import { useCallback, useEffect, useState, useRef } from "react";
import { ProductType } from "../../types";
import parse from 'html-react-parser';
import { buildClient } from "@datocms/cma-client-browser";
import Modal from "../../components/Modal.jsx";
import client from "./client.js";
import ImageSelection from "../../components/ImageSelection.jsx";
import { MDBEcommerceGallery } from 'mdb-react-ecommerce-gallery';
import PayPalFunctionality from "../../components/PayPal.jsx";
import {
  MDBLightbox,
  MDBLightboxItem,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";

// a const variable that shows div with the background as custom image
const Product = ({ product }) => {
  const outsideClickRef = useRef(null);

  const handleOutsideClick = () => {
    outsideClickRef.current?.click();
  };

  const [optSmModal, setOptSmModal] = useState(false);

  const toggleShow = () => setOptSmModal(!optSmModal);

  return (
    <div className="shoppingGrid">

      {
        product.images.length !==0 && (
          // <ImageSelection></ImageSelection>
          <ImageSelection name={product.name} images={product.images} image={product.image.url}></ImageSelection>
        )

      }
      
          { product.images.length ===0 && (
            <MDBLightbox className="d-flex justify-item-center w-auto m-auto">
    <MDBLightboxItem
      src={`${product.image.url}?h=500`}
      alt={product.name}
      caption={product.name}
      style={{
        objectFit: "cover",
        height: "400px",
        width: "400px",
        maxWidth: "100%",
        maxHeight: "100%",
        aspectRatio: "1/1",
      }}
      fullscreenSrc={`${product.image.url}?h=800`}
    />
    <p>click on image to enlarge</p>
  </MDBLightbox>
          )
          }





      <div className="product-info">
        <h2>{product.name}</h2>
        <hr className="w-50 mx-auto"></hr>
        <div style={{whiteSpace: "pre-wrap"}}>{parse(product.description)}</div>
        <hr className="w-50 mx-auto"></hr>
        <p>${product.price}</p>
        <div>
                  <MDBBtn size='lg' onClick={toggleShow} color='dark'>Buy Now</MDBBtn>
        </div>
      </div>

       <MDBModal open={optSmModal} tabIndex='-1' setOpen={setOptSmModal}>
        <MDBModalDialog size='lg'>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>To purchase an item</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody style={{textAlign:"left"}}>
 
  <p>This website is a catalog to view desired merch. To purchase the items, send your payment to <strong>DJAlphamusic</strong> on Venmo, along with the following:</p>
  <ul className="">
    <li>Name of the Merch</li>
    <li>Size(s)</li>
    <li>Quantity</li>
    <li>Mailing Address</li>
    <li>Along with a way to contact you</li>
  </ul>
  <h5>For more information, contact using the following:</h5>
  <ul>
    <li>twitch whisper to @djalphamusic</li>
    <li>text: 714-712-0866</li>
    <li>email: deejayalpha@me.com</li>
    </ul>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

    </div>
  );
};

export default function Products() {
  const [isFetching, setIsFetching] = useState(false);
  // have a set state using the word data
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      try {
        const result = await client.request(query);

        setData(result.allProducts);
        console.log(result.allProducts)
        setIsFetching(false);
      } catch (error) {
        setIsFetching(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="container-fuild">
      <div className="row">
        {data.map((product) => (
          <div key={"product" + product.id}>
            <Product product={product} />
          </div>
        ))}
      </div>
    </div>
  );
}
const query = `
query info {
  allProducts {
    id
    name
    price
    description
    image {
      id
      url
    } 
    images {
      id
      url(imgixParams: {h: "500"})
      src: url(imgixParams: {h: "500"})
      thumbnail: url(imgixParams: {h: "200"})
      alt: title
    } 
    _status
    _firstPublishedAt
  }

  _allProductsMeta {
    count
  }
}
  
  
`;

import React from 'react'
import backgroundimage from '../../images/background/ripple-rings.svg'
import fashionModel from '../../images/fashion/fashionmodel1.png'
import fashionModel2 from '../../images/fashion/june.jpg'
import donut from '../../images/background/pop_donuts.jpg'
import Shop from '../shop/shop'
import Headers from '../../components/headers/headers'
import Products from '../shop/products'

export default function Home() {
const styles = {
  'backgroundImage': 'url(' + backgroundimage + ')',
  'backgroundRepeat': 'no-repeat',
  'backgroundSize': 'cover',
'minHeight': '70vh',

}

const modelBackground = {
  'backgroundImage': 'url(' + fashionModel2 + ')',
  'backgroundRepeat': 'no-repeat',
  'backgroundSize': 'cover',
  'backgroundPosition': 'right',
// 'min-height': '80vh',

}
const donutBackground = {
  'backgroundImage': 'url(' + donut + ')',
  'backgroundRepeat': 'no-repeat',
  'backgroundSize': 'cover',
  'backgroundPosition': 'right',
  'paddingTop':'2rem',
  'paddingBottom':'2rem'
// 'min-height': '80vh',

}
  return (
    <div className="container-fluid">

        <div className="row gx-5" style={styles}>
          <div className="col-md-4 col-sm-12">
          <img src={fashionModel} alt="fashion model" className="img-fluid" />
          <div className='bg-dark display-5 text-white'>

          <p> FASHION, COFFEE, Donuts</p>
          </div>
          </div>
          <div className="col-md-8 col-sm-12" style={modelBackground} >
            <></>
          </div>
        </div>

      <section style={donutBackground} className='row sectionShadow '>
<div className='bg-light shadow-5 roundedorners p-2 col-lg-6 col-md-8 col-10 offset-lg-3 offset-md-2 offset-1'>

  <h2>To purchase an items</h2>
  <p>This website is a catalog to view desired merch. To purchase the items, send your payment to <strong>DJAlphamusic</strong> on Venmo, along with the following:</p>
  <ul>
    <li>Name of the Merch</li>
    <li>Size(s)</li>
    <li>Quantity</li>
    <li>Mailing Address</li>
    <li>Along with a way to contact you</li>
  </ul>
  <h3>For more information, contact using the following:</h3>
  <ul>
    <li>twitch whisper to @djalphamusic</li>
    <li>text: 714-712-0866</li>
    <li>email: deejayalpha@me.com</li>
  </ul>
</div>
      </section>
      <div className="row sectionShadow">
        <Headers title="Shop"/>
        {/* <Shop></Shop> */}
        <Products></Products>
      </div>

    </div>
  )
  
}

// import React from 'react'

// export default function Home() {
//   return (
//     <div>Home</div>
//   )
// }


import React from 'react'
import Products from './products'
export default function Shop() {
  return (
    <div>

<Products></Products>
    </div>
   
  )
}

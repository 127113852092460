import React, { useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBIcon,
  MDBCollapse
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
export default function Navbar() {
  const [showNav, setShowNav] = useState(false);

  return (
    <MDBNavbar expand='lg' light bgColor='light'>
      <MDBContainer fluid>
        <MDBNavbarBrand href='#'>The Le Donut Shop</MDBNavbarBrand>
        {/* <MDBNavbarToggler
          type='button'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowNav(!showNav)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler> */}
        {/* <MDBCollapse navbar show={showNav}> */}
          <MDBNavbarNav>
            {/* <MDBNavbarItem> */}
              {/* <Link to="/">
              <MDBNavbarLink aria-current='page'>
                Home
              </MDBNavbarLink>
              </Link> */}

{/* <MDBNavLink to="/home">Home</MDBNavLink> */}
            {/* </MDBNavbarItem> */}
            {/* <MDBNavbarItem>
              <Link to='/about'><MDBNavbarLink >About</MDBNavbarLink></Link>
              
            </MDBNavbarItem>
            <MDBNavbarItem>
              <Link to='/shop'><MDBNavbarLink >Shop</MDBNavbarLink></Link>
            </MDBNavbarItem> */}

            {/* <MDBNavbarItem>
              <MDBNavbarLink disabled href='#' tabIndex={-1} aria-disabled='true'>
                Disabled
              </MDBNavbarLink>
            </MDBNavbarItem> */}
          </MDBNavbarNav>
        {/* </MDBCollapse> */}
      </MDBContainer>
    </MDBNavbar>
  );
}